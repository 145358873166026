<template>
    <div>
        <blog-post-component/>
        <footer-component/>
    </div>
</template>

<script>
    import BlogPostComponent from "@/components/blogs/BlogPostComponent";
    import FooterComponent from "@/layouts/app-layout/footer/FooterComponent";
    
    export default {
        name: "BlogPost",
        title: "Blog Post | Turismo BC",
        components: {BlogPostComponent, FooterComponent }
    }
</script>

<style scoped>

</style>